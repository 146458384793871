var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"text-body-2 px-0 pt-0 pb-1 d-flex align-end"},[_vm._v(" Periodo: "+_vm._s(_vm._f("dateFormat")(_vm.calendario.inicio,"dd/MM/yyyy"))+" à "+_vm._s(_vm._f("dateFormat")(_vm.calendario.fim,"dd/MM/yyyy"))+" "),_c('v-spacer'),_c('v-btn',{staticClass:"white--text mr-2 mb-2 ml-2",attrs:{"color":"primary","disabled":!_vm.postagensCalendarios.length},on:{"click":_vm.gerarpdf}},[_vm._v(" PDF ")]),(_vm.calendario.status == 1 || _vm.calendario.status == -1)?_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}):_vm._e(),(_vm.calendario.status === 1)?_c('v-btn',{staticClass:"white--text mr-2 mb-2 ml-2",attrs:{"color":"success","disabled":!_vm.postagensCalendarios.length},on:{"click":_vm.mudancaStatus}},[_vm._v(" concluir ")]):_vm._e(),(_vm.calendario.status == 1)?_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}):_vm._e(),(_vm.calendario.status == 1)?_c('v-btn',{staticClass:"white--text mb-2 ml-2",attrs:{"color":"secondary"},on:{"click":_vm.addPostagemCalendario}},[_vm._v(" adicionar ")]):_vm._e()],1),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"data-tables  ",class:{ 'data-tables__row-click': _vm.calendario.status === 1 },attrs:{"headers":_vm.headers,"items":_vm.postagensCalendarios,"items-per-page":10},on:{"click:row":_vm.editPostagemCalendario},scopedSlots:_vm._u([{key:"item.data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.data,"dd/MM/yyyy"))+" ")]}},{key:"item.tema",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-start align-center"},[_c('v-sheet',{staticClass:"rounded-circle mr-2",attrs:{"label":"","color":item.tema.cor,"height":"20px","width":"20px"}}),_vm._v(" "+_vm._s(item.tema.descricao)+" ")],1)]}},{key:"item.formato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formato.descricao)+" ")]}},{key:"item.redes_sociais",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},_vm._l((item.redes_sociais),function(rede_social,index){return _c('v-tooltip',{key:index,attrs:{"top":"","max-width":"220px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"pa-1 mr-1",attrs:{"width":"25px","height":"25px","flat":""}},'v-card',attrs,false),on),[_c('v-img',{attrs:{"src":rede_social.icone,"contain":""}})],1)]}}],null,true)},[_c('div',_vm._l((rede_social.locais),function(local,index_local){return _c('span',{key:index_local},[_vm._v(" "+_vm._s(local.descricao)+_vm._s(index_local === rede_social.locais.length - 1 ? "" : ", ")+" ")])}),0)])}),1)]}},{key:"item.deletar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(_vm.calendario.status === 1)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item.id)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()],1)]}}])})],1),(_vm.dialogAddPostagem)?_c('AddEditPostagemMidiaSociais',{attrs:{"dialogAddPostagem":_vm.dialogAddPostagem,"data_inicio":_vm.calendario.inicio,"data_fim":_vm.calendario.fim,"postagem_id":_vm.postagem_id,"edit":_vm.edit,"calendario":_vm.calendario},on:{"update:dialogAddPostagem":function($event){_vm.dialogAddPostagem=$event},"update:dialog-add-postagem":function($event){_vm.dialogAddPostagem=$event},"fetch-postagem":_vm.fetchPostagens}}):_vm._e(),(_vm.pdfDialog && _vm.edit == false)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":_vm.calendario.cliente},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }